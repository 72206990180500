.rodape{

	.rodape-principal{
		padding-top:55px;
		padding-bottom:25px;
		color:#191919;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:bottom;

		.coluna-borda{
			padding-top:5px;
			padding-bottom:5px;

			& + .coluna-borda{
				position:relative;

				&:before{
					content:'';
					display:block;
					background-color:#D1D1D1;

					@include media-breakpoint-up(lg){
						width:1px;
						position:absolute;
						top:0;
						right:100%;
						height:100%;
					}

				}

			}

		}

		.title{
			font-family: 'fira_sansextrabold';

			@include media-breakpoint-up(lg){
				display:inline-block;
				margin-right:4px;
			}

		}

		.link{

			& + .link{

				&:before{
					content:'/';
					margin:0 3px;
				}

			}

		}

	}

	.rodape-copyright{
		background-color:$theme;
		color:#FFF;
		padding-top:18px;
		padding-bottom:11px;
		font-family: 'fira_sansmedium';
		font-size:10px;

		.gv8{
			max-width:44px;
			margin-left:26px;
		}

	}

}