.form-tipo-contato{

	form{

		.form-group{
			margin-bottom:20px;

			.form-control{
				resize:none;
				background-color:#EEEEEE;
				@include sombra(#EEEEEE);
				min-height:44px;
				border:none;
				border-radius:0;
				@include place(#676767);
				font-size:14px;
				padding-left:14px;
				font-family: 'fira_sansregular';
			}

		}

	}

}