@import "_home.scss";

.internas{
	padding-bottom:40px;
	position:relative;

	&:before{
		content:'';
		display:block;
		width:100%;
		position:absolute;
		top:100%;
		left:0;
		background-color:rgba(#d9d9d9,0.3);
		height:1px;
		box-shadow:0 -2px 8px 2px rgba(#ccc,0.4);
	}

	.header-internas{
		font-size:29px;
		padding-top:33px;
		padding-bottom:42px;
		background-color:#E4E4E4;
		position:relative;
		text-align:center;
		margin-bottom:50px;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			top:0;
			background-color:inherit;
			height:100%;
			width:100vw;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

		.title{
			font-size:1em;
			font-family: 'fira_sansextrabold';
			color:#191919;
			margin-bottom:0;
		}

		&.header-internas-borda{

			.title{

				& > span{
					position:relative;
					display:inline-block;
					padding-bottom:14px;
					
					@include beforeAfter{
						content:'';
						display:inline-block;
						position:absolute;
						left:50%;
						transform:translateX(-50%);
					}

					&:before{
						top:100%;
						width:calc(100% - 8px);
						height:1px;
						background-color:#BEBEBE;

						@include media-breakpoint-up(sm){
							min-width:240px;
						}

					}

					&:after{
						top:calc(100% - 5px);
						width:calc(calc(100% - 8px) * 0.30);
						height:5px;
						background-color:$theme;

						@include media-breakpoint-up(sm){
							min-width:74px;	
						}
						
					}

				}

			}

		}

	}

	.title-17{
		font-size:17px;
	}

	.title-20{
		font-size:20px;
	}

	.title-22{
		font-size:22px;
	}

	.title-27{
		font-size:27px;
	}

	.title-30{
		font-size:30px;
	}

	&.internas-servicos{

		.header-internas{
			margin-bottom:90px;
		}

	}

	&.internas-quem-somos{
		padding-bottom:0;
	}

	&.internas-solucoes{

		.row-especial{
			padding-top:10px;
			// align-items:flex-end;

			& > *{

				@include media-breakpoint-up(lg){
					// display:flex;
				}

			}

		}		

	}

}

.trajetoria{
	background-color:#ededed;
	padding-top:55px;
	padding-bottom:46px;
	position:relative;

	@include beforeAfter{
		content:'';
		display:block;
		position:absolute;
		top:0;
		width:100vw;
		height:100%;
		background-color:inherit;
	}

	&:before{
		left:100%;
	}

	&:after{
		right:100%;
	}

}

.item-float{
	@extend .float-left;
	font-size:17px;
	color:#000;
	margin-bottom:10px;
	word-break:break-all;
	padding-left:10px;
	padding-right:10px;

	@include media-breakpoint-only(sm){
		width:50%;

	}

	@include media-breakpoint-up(md){
		width:33.333%;
	}

}

.objetivos-sobre{
	padding-top:68px;
	padding-bottom:40px;
}