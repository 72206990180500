@font-face {
    font-family: 'fira_sansregular';
    src: url('#{$fonts}/firasans-reg/firasans-regular-webfont.eot');
    src: url('#{$fonts}/firasans-reg/firasans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/firasans-reg/firasans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/firasans-reg/firasans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/firasans-reg/firasans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/firasans-reg/firasans-regular-webfont.svg#fira_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sansmedium';
    src: url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.eot');
    src: url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.woff') format('woff'),
         url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/FiraSans-Medium/firasans-medium-webfont.svg#fira_sansmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sansextrabold';
    src: url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.eot');
    src: url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.woff') format('woff'),
         url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.ttf') format('truetype'),
         url('#{$fonts}/FiraSans-ExtraBold/firasans-extrabold-webfont.svg#fira_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sansbold';
    src: url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.eot');
    src: url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/FiraSans-Bold/firasans-bold-webfont.svg#fira_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}