.card-solucao{
	color:#505050;
	margin-bottom:40px;
	text-align:center;
	padding-top:20px;

	@include media-breakpoint-up(lg){
		text-align:left;
		
		&,
		& > a{
			display:flex;
			flex-direction:column;
			height:100%;
			align-items:flex-end;
		}

	}

	.img-card{
		margin-bottom:10px;

		@include media-breakpoint-up(sm){
			// display:flex;
			// flex-wrap:wrap;
			// align-items:flex-end;
			// height:100%;
			min-height:280px;
			display:flex;
			align-items:flex-end;
			justify-content:center;
		}

	}

	.content-card{

		@include media-breakpoint-up(sm){
			display:flex;
			align-items:flex-end;
			justify-content:space-between;
			height:100%;
			flex-direction:column;

			& > *{
				width:100%;
			}

		}

		.title{
			font-size:22px;
			color:#000;
			text-align:center;
			margin-bottom:8px;
			font-family: 'fira_sansextrabold';
		}

		.desc{
			margin-bottom:10px;
		}

	}

}