.header-section{
	padding-bottom:5px;
	margin-bottom:30px;
	position:relative;

	&:before{
		content:'';
		display:block;
		width:68px;
		height:5px;
		border-radius:3px;
		position:absolute;
		top:100%;
		left:50%;
		transform:translateX(-50%);
		background-color:$theme2;
	}

	.subtitle{
		font-size:15px;
		font-family: 'fira_sansextrabold';
		color:$theme2;
		margin-bottom:6px;
	}

	.title{
		font-size:30px;
		font-family: 'fira_sansbold';
		color:#1D1D1D;
		margin-bottom:10px;
	}

}

.text-lg-left,
.header-section-left{

	@include media-breakpoint-up(lg){

		&.header-section-left{

			&:before{
				left:0;
				transform:none;
			}

		}
	}

}