.paginacao-custom{

	.page-item{
		@include margin(0,5);

		.page-link{
			width:40px;
			height:40px;
			padding:0;
			display:flex;
			align-items:center;
			justify-content:center;
			border-radius:0;
			font-size:16px;
			@include button-variant(#e4e4e4,#e4e4e4);
			color:#505050;

			@include hover-focus{
				color:$theme2;
			}

		}

	}

}