.card-objetivos{
	max-width:322px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:15px;

	.img-card{
		margin-bottom:15px;
		width:128px;
		display:flex;
		justify-content:center;
		align-items:center;
		text-align:center;
		height:128px;
		border-radius:100%;
		margin-left:auto;
		margin-right:auto;
		border:1px solid #454545;
	}

	.card-content{

		.title{
			font-family: 'fira_sansmedium';
			font-size:18px;
			margin-bottom:8px;
			color:lighten($theme2,10%);
			text-align:center;
		}

		.desc{
			color:#666666;
			line-height:1.2;
		}

	}

}