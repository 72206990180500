.menu-topo{
	list-style-type:none;
	margin-bottom:0;
	padding-left:0;

	@include media-breakpoint-only(lg){
		margin-left:40px;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		flex-grow:1;
		max-width:664px;
	}

	& > li{

		& > a{
			color:$theme;
			font-family: 'fira_sansextrabold';
			font-size:15px;

			@include media-breakpoint-down(md){
				display:block;
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}
			
			@include media-breakpoint-up(lg){
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					height:5px;
					top:calc(100% + 6px);
					width:100%;
					right:-16px;
					background-color:$theme2;
					transition:opacity 0.6s linear;
					opacity:0;
				}

			}

		}

		&.active > a,
		&:hover  > a{
			border-color:darken($theme2,10%);

			@include media-breakpoint-down(md){
				background-color:$theme2;
				color:#FFF;
			}
			
			@include media-breakpoint-up(lg){
				color:$theme2;

				&:before{
					animation:fadeInLeft 0.6s linear;
					opacity:1;
				}

			}

		}

	}

}