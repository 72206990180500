.segura-carousel{
	position:relative;

	.slick-slider{
		z-index:100;
	}

	.controls-custom{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:flex-end;
		padding-bottom:20px;

		@include media-breakpoint-up(sm){
			padding-bottom:85px;
		}

		.clearfix{

			& > *{
				z-index:300;
				width:(23 / 14) * 1em;
				height:(23 / 14) * 1em;
				line-height:(23 / 14) * 1em;
				background-color:transparent;
				border-radius:100%;
				text-align:center;
				font-size:14px;
				color:#FFF;
				position:relative;

				@include hover-focus{
					background-color:#811D2B;
				}

			}

		}

	}

	&.carousel-varios-itens,
	&.carousel-unico-item{

		.controls-custom{

			.clearfix{

				& > a{
					color:#000;
					font-weight:bold;
					font-size:22px;
					width:auto;
					height:auto;
				}

			}

		}

	}

	&.carousel-varios-itens{
		padding-left:30px;
		padding-right:30px;

		.controls-custom{
			padding-bottom:0;
			align-items:center;

			.clearfix{
				width:100%;

				a{

					@include hover-focus{
						background-color:inherit;
						color:$theme2;
					}

				}

			}

		}

	}

	&.carousel-unico-item{
		max-width:446px;
		margin-left:auto;
		margin-right:auto;

		.controls-custom{
			padding-bottom:0;
			bottom:0;
			right:0;
			width:auto;
			height:auto;
			top:unset;
			left:unset;
			z-index:500;
			min-width:68px;
			background-color:$theme2;
			padding-top:7px;
			padding-bottom:7px;

			&:before{
				content:'';
				display:block;
				position:absolute;
				right:calc(100% - 1px);
				top:0;
				width:20px;
				height:100%;
				background-color:inherit;
				clip-path:polygon(100% 0, 100% 100%, 0 100%);
			}

			.clearfix{
				min-width:35px;

				a{
					color:#FFF;

					@include hover-focus{
						background-color:inherit;
						color:#000;
					}

				}

			}

		}

	}

}

.ondas-section{
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:107px;
	z-index:100;
	background-position:center center;
	background-repeat:repeat-x;

	@include media-breakpoint-down(xs){
		display:none;
	}

}

.produtos{
	padding-top:75px;
	background-repeat:no-repeat;
	background-size:cover;
	padding-bottom:30px;
}

.texto-limitado{
	overflow:auto;
	text-overflow:ellipsis;

	&.max-texto-240{
		
		@include media-breakpoint-up(lg){
			max-height:240px;
		}

	}

}

.empresa{
	background-color:#1A3071;
	color:#FFF;
	padding-bottom:85px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
	position:relative;

	@include media-breakpoint-down(lg){
		padding-top:40px;
	}

	.figure-globo{

		@include media-breakpoint-down(md){

			img{
				@include img-fluid();
			}

		}

		@include media-breakpoint-up(lg){
			min-height:565px;
			position:relative;
			margin-bottom:0;

			img{
				position:absolute;
				top:0;
				left:0;
				width:604px;
				height:565px;
			}

		}

	}

}