$coresBase: (
	'red' 			: #F44336,
	'pink' 			: #E91E63,
	'purple' 		: #9C27B0,
	'deep-purple' 	: #673AB7,
	'indigo' 		: #3F51B5,
	'blue' 			: #2196F3,
	'light-blue'	: #03A9F4,
	'cyan' 			: #00BCD4,
	'teal' 			: #009688,
	'green' 		: #4CAF50,
	'light-green' 	: #8BC34A,
	'lime' 			: #CDDC39,
	'yellow' 		: #FFEB3B,
	'amber' 		: #FFC107,
	'orange' 		: #FF9800,
	'deep-orange' 	: #FF5722,
	'brown' 		: #795548,
	'grey' 			: #9E9E9E,
	'blue-grey' 	: #607D8B
);

// Gerando a paleta de cores do material design.
// Caso queira usá-la, basta usar o método map-get() do sass
// Ex:
// .text-green-darken-2 {
// 		color: map-get($cores, 'green-darken-2');
// }
$cores: allcolors-variantion($coresBase);

$fonts: '../fonts';

$theme:#000063;
$theme2:#AD1A1A;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1110px
);