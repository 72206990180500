@import "_fontfaces";

body{
	font-size:14px;
	font-family: 'fira_sansregular';
	color:#AAAAAA;
}

*{
	font-feature-settings: "liga" 0;
}

.slick-slider{
	text-align:center;

	.item{
		
		img{
			display:inline-block;
		}

	}

}

.internas img{
	@include img-fluid();
}

.btn,
a{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

// FONTS USADAS
.fira-sans-reg{
	font-family: 'fira_sansregular';	
}

.fira-sans-med{
	font-family: 'fira_sansmedium';
}

.fira-sans-ex-bld{
	font-family: 'fira_sansextrabold';
}

.fira-sans-bld{
	font-family: 'fira_sansbold';
}

#app{
	overflow:hidden;
}

@include text-emphasis-variant('.text-white',#FFF);
@include text-emphasis-variant('.text-gray', #AAAAAA);
@include text-emphasis-variant('.text-191919',#191919);
@include text-emphasis-variant('.text-505050',#505050);
@include text-emphasis-variant('.text-black',#000);

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

.alert{
    border-radius:0;
    @extend .animated;
    @extend .fadeIn;

    &.alert-success,
    &.alerta-success{
        background: map-get($cores,'green-darken-2');
        color:#FFF;
    }

    &.alert-danger,
    &.alerta-danger{
        background: map-get($cores,'red-darken-2');
        color:#FFF;
    }

    &.alert-info,
    &.alerta-info{
        background: map-get($cores,'blue-darken-2');
        color:#FFF;
    }

    &.alert-warning,
    &.alerta-warning{
        background: map-get($cores,'yellow-darken-2');
        color:#FFF;
    }
}

lazy-image{
	background-color:inherit;
}

.alertas{
	position: fixed;
    z-index:900;
	bottom:0;
	right:0;
	padding:15px;
	max-width:100%;
	min-width:250px;

	.alert{
		box-shadow:0 0 8px rgba(#000,0.8);
	}
}