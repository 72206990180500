.card-motivos{
	padding-top:20px;
	text-align:center;
	transition:box-shadow 0.6s, background-color 0.6s linear;
	margin-bottom:32px;
	border-radius:8px;

	@include media-breakpoint-down(md){
		background-color:#FFF;
	}

	.figure-card{
		border:10px solid $theme2;
		border-radius:100%;
		font-size:112px;
		width:1em;
		height:1em;
		line-height:1em;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		padding:14px;
		margin-right:auto;
		margin-left:auto;
		position:relative;
		transition:border-color 0.6s linear;
		
		&:before{
			content:'';
			display:block;
			position:absolute;
			top:calc(100% + 7px);
			transform:translateX(-50%);
			border:14px solid transparent;
			border-top-color:$theme2;
			left:50%;
			transition:border-top-color 0.6s linear;
		}

	}

	.desc-card{
		padding-top:12px;
		padding-left:10px;
		padding-right:10px;
		padding-bottom:5px;

		& > *{
			line-height:1.2;
		}

		.title{
			font-size:15px;
			font-family: 'fira_sansbold';
			color:#000;
			margin-bottom:10px;
		}

		.desc{
			font-size:14px;
			margin-bottom:20px;
		}

	}

	&:hover{
		box-shadow:10px 14px 10px -2px rgba(0,0,0,0.2);
		background-color:#FFF;

		.figure-card{
			border-color:$theme;

			&:before{
				border-top-color:$theme;
			}

		}

	}

}