.servico-item{
	border-bottom:6px solid #dbdbdb;
	margin-bottom:35px;

	&:last-child{
		border-bottom:0;
	}

	.row{

		& > *{
			margin-bottom:30px;
		}

		.col-img{
			max-width:220px;
			margin-left:auto;
			margin-right:auto;
		}

		.col-text{
			flex-grow:1;
			max-width:100%;
			color:#505050;

			@include media-breakpoint-up(sm){
				width:calc(100% + 34px);
			}

			.title{
				font-family: 'fira_sansextrabold';
				font-size:22px;
				color:black;
				line-height:1;
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:calc(100% + 11px);
					left:0;
					width:58px;
					height:4px;
					background-color:#000;
				}

			}

			& > .row{

				& > *{
					margin-bottom:35px;
				}

				.bar-col{
					width:100%;
					background-color:#bebebe;
					display:block;
					height:1px;
				}

			}

		}

	}

}