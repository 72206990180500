.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 111px 104px;

	&.icon-br{
		width: 32px;
		height: 21px;
		background-position: 0px -47px;
	}

	&.icon-en{
		width: 31px;
		height: 21px;
		background-position: -42px -47px;
	}

	&.icon-gps{
		width: 27px;
		height: 37px;
		background-position: 0px 0px;
	}

	&.icon-message{
		width: 33px;
		height: 24px;
		background-position: -78px 0px;
	}

	&.icon-star{
		width: 26px;
		height: 26px;
		background-position: 0px -78px;
	}

	&.icon-tel{
		width: 31px;
		height: 32px;
		background-position: -37px 0px;
	}

	&.icon-triple-arrow-top{
		width: 10px;
		height: 18px;
		background-position: -83px -47px;
	}

}
