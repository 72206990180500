.topo{

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		background-color:rgba(#000,0.5);
		opacity:0;
		transition:opacity 0.6s linear;
	}

	.bar-topo{
		background-color:$theme;
		padding-top:7px;
		padding-bottom:2px;

		.idiomas-disponiveis{
			text-align:right;

			& > *{
				@include margin(0,3);
				margin-bottom:6px;
			}

		}

	}

	.nav-content{

		@include media-breakpoint-up(xl){
			position:relative;

			&:before{
				content:'';
				border:124px solid transparent;
				border-bottom-color:#EDF0F3;
				position:absolute;
				left:100%;
				z-index:-2;
				top:-140px;
			}
		}

		@include media-breakpoint-up(lg){
			@include make-container-max-widths();
			@include make-container();
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
			padding-top:20px;
			padding-bottom:2px;

			& > *{
				margin-bottom:20px;
			}

		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			transition:right 0.6s linear;
			right:-240px;
			width:240px;
			height:100%;
			background-color:#FFF;
			color:#000;
			border-left:1px solid $theme;
			overflow:auto;
			z-index:1000;

			.logo-topo{
				padding:15px;
				// margin-bottom:15px;
			}

		}

	}

	&.fx{
		
		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			max-width:100%;
			animation:fadeInDown 0.6s linear;
			width:100%;
			z-index:700;
			box-shadow:0 0 8px rgba(#000,0.5);
		}

	}

}

body.menu-active{

	.bg-menu{
		height:100%;
		opacity:1;
		z-index:900;
	}

	.topo{

		.nav-content{
			right:0;
		}

		.btn-responsivo{
			transform:rotate(45deg);

			.bar{

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

				@include beforeAfter{
					transform:rotate(90deg);
				}

			}

		}

	}

}