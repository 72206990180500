.article-info{
	text-align:center;
	margin-bottom:50px;

	.box-icon{
		margin-bottom:12px;
		min-height:37px;
	}

	.box-text{
		color:#6B6B6B;
	}

}