.redes-sociais{

	& > *{
		font-size:14px;
		width:(66 / 14) * 1em;
		height:(66 / 14) * 1em;
		line-height:(66 / 14) * 1em;
		text-align:center;
		display:inline-block;
		border-radius:100%;
		@include margin(0,4);
	}

	&.redes-sociais-rodape{
		text-align:center;

		& > *{
			background-color:#e4e4e4;
			color:#000;

			@include hover-focus{
				color:$theme2;
			}

		}

	}

}