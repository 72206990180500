@import "attention_seekers/_attention_seekers.scss";
@import "bouncing_entrances/_bouncing_entrances.scss";
@import "bouncing_exits/_bouncing_exits.scss";
@import "fading_entrances/_fading_entrances.scss";
@import "fading_exits/_fading_exits.scss";
@import "flippers/_flippers.scss";
@import "lightspeed/_lightspeed.scss";
@import "rotating_entrances/_rotating_entrances.scss";
@import "rotating_exits/_rotating_exits.scss";
@import "sliding_entrances/_sliding_entrances.scss";
@import "sliding_exits/_sliding_exits.scss";
@import "specials/_specials.scss";
@import "zooming_entrances/_zooming_entrances.scss";
@import "zooming_exits/_zooming_exits.scss";


.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes mouseBanner{

	0%{
		transform:translateY(0);
	}

	100%{
		transform:translateY(4px);	
	}

}

@keyframes arrowLeft{

	0%{
		transform:translateX(0);
	}

	100%{
		transform:translateX(-4px);	
	}

}

@keyframes arrowRight{

	0%{
		transform:translateX(0);
	}

	100%{
		transform:translateX(4px);	
	}

}

@keyframes explodeIcon{
	0%{
		transform:scale(0);
		opacity:0.6;
	}
	
	100%{
		transform:scale(8);
		opacity:0;
	}
}

@keyframes dot{
  50%{
    transform: translateX(96px)
  }
}

@keyframes dots{
  50%{
    transform: translateX(-31px)
  }
}