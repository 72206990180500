.card-trajetoria{
	margin-bottom:24px;
	text-align:center;
	color:#505050;

	.title{
		font-family: 'fira_sansextrabold';
		font-size:17px;
		margin-bottom:10px;
		padding-left:12px;
		padding-right:12px;
		position:relative;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			padding-top:3px;
			padding-bottom:3px;
			width:7px;
			background-color:transparent;
			z-index:5;
			border-top:7px solid $theme;
			border-bottom:7px solid #000;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

		span{
			color:$theme;
		}

	}

}