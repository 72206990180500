.btn-irtopo{
	display:none;
	padding:0;
	font-size:88px;
	width:1em;
	height:1em;
	position:absolute;
	bottom:16px;
	right:0;
	border-radius:100%;
	z-index:600;
	@include sombra($theme);
	font-family: 'fira_sansregular';
	border:6px solid #FFF !important;

	.text{
		font-size:14px;
	}

	.icon{
		animation:mouseBanner infinite alternate 2s;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		align-items:center;
		flex-direction:column;
		justify-content:center;
	}

}

