.btn{
	font-family: 'fira_sansbold';
	font-size:14px;

	&.btn-radius-30{
		border-radius:30px;
	}

	&.btn-pill{
		border-radius: 2rem;
	}

	&.min-width-111{
		min-width:111px;
	}

	&.min-width-182{
		min-width:182px;
	}

	&.min-width-212{
		min-width:212px;
	}

	&.btn-theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn-11-12{
		padding:11px 12px;
	}

	&.btn-13-8-13-24{
		padding:13px 8px 13px 24px;
	}

	&.pdg-17-20{
		padding:17px 20px;
	}

	&.btn-theme-2{
		@include button-variant(lighten($theme2,10%),lighten($theme2,10%));
		color:#FFF;
	}

	&.btn-theme-to-theme-2{
		@include button-variant(lighten($theme,10%),lighten($theme,10%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten($theme2,10%),lighten($theme2,10%));
			color:#FFF;
		}

	}

	.badge{
		width:33px;
		height:33px;
		line-height:33px;
		border-radius:100%;
		padding:0;
		background-color:$theme2;
		color:#FFF;
		margin-left:5px;
	}

}